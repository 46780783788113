import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, FormControlLabel, FormGroup, Switch, IconButton, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import { operations, selectors } from "modules/customer/account/users";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

const StyledFormControlLabel = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-0.5),
        paddingBottom: theme.spacing(1)
    }
}))(FormControlLabel);

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

const EditUser = (props) => {
    const { id } = props;
    const dispatch = useDispatch();
    const featureFlags = useSelector(selectFeatureFlags);
    const user = useSelector(state => selectors.user(state));
    const editUserVisibility = useSelector(state => selectors.editUserVisibility(state));
    const show = (editUserVisibility.isVisible) && (id === editUserVisibility.id);

    React.useEffect(() => {
        if (show) {
            dispatch(operations.getUser(id));
        }
    }, [dispatch, show, id]);

    const handleEditUserClick = () => {
        dispatch(operations.showEditUser(id));
    };

    const handleSaveClick = () => {
        dispatch(operations.editUser());
    };

    const handleCancelClick = () => {
        dispatch(operations.hideEditUser());
        dispatch(operations.clearUser());
    };

    const handleCanAccessPerformanceChange = () => {
        const newUser = { ...user, canAccessPerformance: !user.canAccessPerformance };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessPortfolioChange = () => {
        const newUser = { ...user, canAccessPortfolio: !user.canAccessPortfolio };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessLocationChange = () => {
        const newUser = { ...user, canAccessLocation: !user.canAccessLocation };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessReportChange = () => {
        const newUser = { ...user, canAccessReport: !user.canAccessReport };
        dispatch(operations.setUser(newUser));
    };

    const handleCanAccessProductChange = () => {
        const newUser = { ...user, canAccessProduct: !user.canAccessProduct };
        dispatch(operations.setUser(newUser));
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Edit">
                <StyledIconButton size="small" onClick={handleEditUserClick}>
                    <EditRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>
                            Edit user
                        </Typography>
                        <Typography variant="subtitle1" component="div" gutterBottom>
                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="subtitle1" component="div" gutterBottom>
                            {user.email}
                        </Typography>
                    </>
                }
                content={
                    <FormGroup>
                        <StyledFormControlLabel
                            control={
                                <StyledSwitch
                                    size="small"
                                    color="default"
                                    checked={user.canAccessPerformance}
                                    onChange={handleCanAccessPerformanceChange}
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Performance
                                </Typography>
                            }
                        />
                        <StyledFormControlLabel
                            control={
                                <StyledSwitch
                                    size="small"
                                    color="default"
                                    checked={user.canAccessPortfolio}
                                    onChange={handleCanAccessPortfolioChange}
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Portfolio
                                </Typography>
                            }
                        />
                        <StyledFormControlLabel
                            control={
                                <StyledSwitch
                                    size="small"
                                    color="default"
                                    checked={user.canAccessLocation}
                                    onChange={handleCanAccessLocationChange}
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Location
                                </Typography>
                            }
                        />
                        {featureFlags.enableCustomerToolsReport && (
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={user.canAccessReport}
                                        onChange={handleCanAccessReportChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle1" component="div">
                                        Report
                                    </Typography>
                                }
                            />
                        )}
                        {featureFlags.enableCustomerToolsProduct && (
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={user.canAccessProduct}
                                        onChange={handleCanAccessProductChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle1" component="div">
                                        Product
                                    </Typography>
                                }
                            />
                        )}
                    </FormGroup>
                }
                actions={
                    <>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleSaveClick}>
                            Save
                        </Button>
                    </>
                }
            />
        </>
    );
};

EditUser.propTypes = {
    id: PropTypes.string.isRequired
};

export default EditUser;

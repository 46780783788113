import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import PageNotFound from "./PageNotFound";

import Account from "./account/Account";
import Dashboard from "./dashboard/Dashboard";
import Data from "./data/Data";
import Help from "./help/Help";
import TermsOfUse from "./termsOfUse/TermsOfUse";

import Performance from "pages/customer/insights/performance/Performance";
import Portfolio from "pages/customer/insights/portfolio/Portfolio";
import PortfolioNew from "pages/customer/insights/portfolioNew/Portfolio";
import Range from "pages/customer/insights/range/Range";
import Location from "pages/customer/tools/location/Location";
import Product from "pages/customer/tools/product/Product";
import Report from "pages/customer/tools/report/Report";

import BasicPerformanceReporting from "features/customer/products/basicPerformanceReporting/BasicPerformanceReporting";

import { selectUserInfo } from "modules/auth/authSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

const Router: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const featureFlags = useAppSelector(selectFeatureFlags);
    const userInfo = useAppSelector(selectUserInfo);
    const isAccountEditor = userInfo.isAccountEditor;
    const isAccountSetupComplete = userInfo.isAccountSetupComplete;

    if (!isAccountSetupComplete) {
        return (
            <Switch>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route>
                    <Redirect to="/dashboard" />
                </Route>
            </Switch>
        );
    }

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/dashboard" />
            </Route>
            <Route path="/account">
                <Account />
            </Route>
            <Route path="/dashboard">
                <Dashboard />
            </Route>
            {isAccountEditor && (
                <Route path="/data">
                    <Data />
                </Route>
            )}
            <Route path="/help">
                <Help />
            </Route>
            {userInfo.canAccessPerformance && (
                <Route path="/insights/performance">
                    <Performance />
                </Route>
            )}
            {userInfo.canAccessPortfolio && (
                <Route path="/insights/portfolio">
                    {featureFlags.enableCustomerInsightsPortfolioNew && (<PortfolioNew />)}
                    {!featureFlags.enableCustomerInsightsPortfolioNew && (<Portfolio />)}
                </Route>
            )}
            <Route path="/insights/range">
                <Range />
            </Route>
            {userInfo.canAccessLocation && ((isMobile && featureFlags.enableCustomerToolsLocationMobile) || (!isMobile && featureFlags.enableCustomerToolsLocationDesktop)) && (
                <Route path="/tools/location">
                    <Location />
                </Route>
            )}
            {featureFlags.enableCustomerToolsReport && userInfo.canAccessReport && (
                <Route path="/tools/report">
                    <Report />
                </Route>
            )}
            {featureFlags.enableCustomerToolsProduct && userInfo.canAccessProduct && (
                <Route path="/tools/product">
                    <Product />
                </Route>
            )}

            {/* ToDo: PoC insight, will be removed */}
            <Route path="/products/basic_performance_reporting">
                <BasicPerformanceReporting />
            </Route>
            <Route path="/terms-of-use">
                <TermsOfUse />
            </Route>
            <Route>
                <PageNotFound />
            </Route>
        </Switch>
    );
};

export default Router;

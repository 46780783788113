import React from "react";

import { Box, Container, Grid, Typography } from "@material-ui/core";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const Product: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);

    return (
        <Box maxWidth="100vw" data-cy="pages-customer-tools-product">
            <Container maxWidth={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            {userInfo.companyDisplayName}
                        </Typography>
                        <Box paddingBottom={1} data-cy="title">
                            <Typography variant="h3">
                                Product
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Product;
